import { useTranslation } from 'next-i18next';
import { getMinDurationInDays } from '@hotelplan/fdr.regular.fusion.fdr-travel-dates';
import { addDays, getTodaysDate } from '@hotelplan/libs.utils';
import { FdrTravelPeriodType } from '@hotelplan/supergraph-api';
import { useSearchTravelDatesContext } from 'fdr/components/candidate/fdr-search-travel-dates-context';
import { EFdrDatesInputType } from 'fdr/components/local/fdr-search-travel-dates/dropdowns/use-fdr-travel-dates-dropdown-handler';

export const useMixedCalendarConfig = () => {
  const [t] = useTranslation('common');

  const {
    state,
    datesHandler: { isOpened },
    initialState,
    customConfiguration,
  } = useSearchTravelDatesContext();

  const isReturn = isOpened(EFdrDatesInputType.RETURN_DATE);

  const returnDate = state.returnDate || initialState.returnDate;
  const departureDate = state.departureDate || initialState.departureDate;
  const isExactSearchType = state.searchType === FdrTravelPeriodType.Exact;
  const translationPostfix = isExactSearchType ? '.exact' : '';

  const title = t(
    isReturn
      ? `single.return.calendar.title${translationPostfix}`
      : `single.departure.calendar.title${translationPostfix}`
  );

  const month = isReturn ? returnDate : departureDate;

  const minReturnDate =
    state.searchType === FdrTravelPeriodType.Exact
      ? addDays(state.departureDate, 1)
      : addDays(state.departureDate, getMinDurationInDays(state.duration));

  const customConfig = {
    ...customConfiguration,
    onlyDepartureDate: isOpened(EFdrDatesInputType.DEPARTURE_DATE),
    onlyReturnDate: isReturn,
    maxDate: addDays(getTodaysDate(), 365),
    minDate: isReturn ? minReturnDate : undefined,
  };

  return { month, title, customConfig };
};

import { useTranslation } from 'next-i18next';
import React, { ReactElement, useRef } from 'react';
import { E_BUTTON_TYPE } from '@hotelplan/style.hotelplan-style';
import { useSearchTravelDatesContext } from 'fdr/components/candidate/fdr-search-travel-dates-context';
import { FdrMixedCalendar } from 'fdr/components/local/fdr-mixed-calendar/fdr-mixed-calendar';
import { ModalCloseButton } from 'fdr/components/local/fdr-mixed-calendar/fdr-mixed-calendar.styles';
import { EFdrDatesInputType } from 'fdr/components/local/fdr-search-travel-dates/dropdowns/use-fdr-travel-dates-dropdown-handler';
import { FdrCompactDates } from './compact-dates';
import { FdrCompactFooter } from './fdr-compact-footer';
import { FdrMobileModalWrapper, FdrWrapper } from './fdr-compact-select.styles';

interface IFdrCompactMobileProps {
  label?: string;
}

export function FdrCompactMobile({
  label,
}: IFdrCompactMobileProps): ReactElement {
  const [t] = useTranslation(['common', 'forms']);

  const {
    modalOrDropdownHandler: { isControlOpened, closeControl },
    datesHandler: { isOpened, closeCalendar },
    resetLocalState,
    resetState,
  } = useSearchTravelDatesContext();

  const wrapperRef = useRef<HTMLDivElement>();
  const isReturn = isOpened(EFdrDatesInputType.RETURN_DATE);

  return (
    <FdrMobileModalWrapper
      label={label || ''}
      show={isControlOpened}
      className="compact-mobile"
      closeModal={() => {
        resetState();
        closeControl();
        closeCalendar();
      }}
    >
      <FdrWrapper className="mobile" ref={wrapperRef}>
        <FdrCompactDates>
          <FdrMixedCalendar wrapperRef={wrapperRef} desktop={false}>
            <ModalCloseButton
              variant={E_BUTTON_TYPE.ICON_BUTTON}
              className="close-button"
              data-id="close"
              icon={{ name: 'close' }}
              onClick={() => {
                resetLocalState(isReturn);
                closeCalendar();
              }}
            >
              {t('common:close')}
            </ModalCloseButton>
          </FdrMixedCalendar>
        </FdrCompactDates>
      </FdrWrapper>
      <FdrCompactFooter applyText={t('forms:applyButton.datesMobile')} />
    </FdrMobileModalWrapper>
  );
}

import { useTranslation } from 'next-i18next';
import React from 'react';
import {
  FdrTravelDatesInformation,
  formatDuration,
} from '@hotelplan/fdr.regular.fusion.fdr-travel-dates';
import { useSearchTravelDatesContext } from 'fdr/components/candidate/fdr-search-travel-dates-context';
import { FdrDatesController } from 'fdr/components/local/fdr-search-travel-dates/dropdowns/fdr-dates-controller';

type TProps = {};

const AllInMobileFooter: React.FC<TProps> = ({}: TProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('search');

  const { state, customConfiguration } = useSearchTravelDatesContext();

  return (
    <>
      <FdrTravelDatesInformation
        returnDate={state.returnDate}
        duration={formatDuration(t, state.duration)}
        departureDate={state.departureDate}
        onlyDates={customConfiguration?.onlyDatesInfo}
        onlyDepartureDate={customConfiguration?.onlyDepartureDate}
        onlyReturnDate={customConfiguration?.onlyReturnDate}
        language={language}
      />
      <FdrDatesController />
    </>
  );
};

export default AllInMobileFooter;

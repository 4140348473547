import { useToggleState } from '@hotelplan/libs.hooks-react';
import {
  trackModalClose,
  trackModalOpen,
} from 'components/domain/search-travel-dates/tracking';

export type TFdrModalOrDropdownHandler = {
  isControlOpened: boolean;
  openControl(): void;
  closeControl(): void;
};

export function useFdrModalOrDropdown(): TFdrModalOrDropdownHandler {
  const [isModalOpened, openModal, closeModal] = useToggleState(false);

  const openControl = () => {
    if (!isModalOpened) trackModalOpen();

    openModal();
  };

  const closeControl = () => {
    if (isModalOpened) trackModalClose();

    closeModal();
  };

  return { isControlOpened: isModalOpened, openControl, closeControl };
}

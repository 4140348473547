import { TFunction } from 'next-i18next';
import {
  FdrTravelDatesCustomConfiguration,
  getShortInformation,
  IFdrTravelDatesState,
} from '@hotelplan/fdr.regular.fusion.fdr-travel-dates';
import { SELECT_DURATION_EVENT } from './fdr-search-travel-dates.constants';

export function getTranslatedShortInfo(
  t: TFunction,
  travelDatesData: IFdrTravelDatesState,
  config: FdrTravelDatesCustomConfiguration
) {
  return travelDatesData
    ? getShortInformation(t, {
        departureDate: travelDatesData.departureDate,
        returnDate: travelDatesData.returnDate,
        durationData: travelDatesData.duration,
        onlyDepartureDate: config?.onlyDepartureDate,
        onlyReturnDate: config?.onlyReturnDate,
        showDuration: !config?.onlyDatesInfo,
      }).travelDates
    : '';
}

export const selectDuration = input => {
  if (typeof window === 'undefined') return;
  window.dispatchEvent(
    new CustomEvent(SELECT_DURATION_EVENT, { detail: input })
  );
};

import addDays from 'date-fns/addDays';
import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  areTravelDatesEqual,
  FdrTravelDatesConfiguration,
  FdrTravelDatesCustomConfiguration,
  IFdrTravelDatesState,
  useFdrTravelDatesDropdownPosition,
  FdrTravelDatesDesktopDropdown as DropdownCalendar,
} from '@hotelplan/fdr.regular.fusion.fdr-travel-dates';
import { TravelApplySourceType } from '@hotelplan/libs.tracking';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { useSearchTravelDatesContext } from 'fdr/components/candidate/fdr-search-travel-dates-context';
import { useFdrCloseDropdownLogic } from 'fdr/components/local/fdr-search-travel-dates/dropdowns/use-close-dropdown-logic';
import {
  selectDate,
  useFdrFlightTravelDatesHelper,
} from 'fdr/components/local/fdr-search-travel-dates/dropdowns/use-fdr-flight-travel-dates-helper';
import { EFdrDatesInputType } from 'fdr/components/local/fdr-search-travel-dates/dropdowns/use-fdr-travel-dates-dropdown-handler';
import {
  trackCalendarApply,
  trackModalApply,
} from 'fdr/components/local/fdr-search-travel-dates/fdr-search-travel-dates.tracking';

const TravelDatesDesktopDropdownWrap = styled.div(
  sx2CssThemeFn({ '.absolute-dropdown': { width: '770px' } })
);

const NUMBER_OF_MONTH_DESKTOP = 2;
const configuration: FdrTravelDatesConfiguration = {
  hasApplyBtn: true,
  hasCustomWeekDayElement: false,
  hasNavbar: true,
  numberOfMonthsToShow: NUMBER_OF_MONTH_DESKTOP,
  canChangeMonth: true,
  closeOnSaveButtonClick: true,
};

type TProps = React.PropsWithChildren<{
  textInputRef: React.RefObject<HTMLInputElement>;
  extendConfig?: Partial<FdrTravelDatesConfiguration>;
  extendCustomConfig?: Partial<FdrTravelDatesCustomConfiguration>;
  range?: boolean;
}>;

const DEFAULT_DURATION_DAYS = 14;

const AllInDesktop: React.FC<TProps> = ({
  textInputRef,
  children,
  extendConfig = {},
  extendCustomConfig = {},
}: TProps) => {
  const {
    state,
    travelDatesHandlers,
    initialState,
    setTravelDatesData,
    side,
    customConfiguration,
    onChange,
    modalOrDropdownHandler: { isControlOpened, closeControl },
    datesHandler: { closeCalendar, openCalendar, isOpened },
  } = useSearchTravelDatesContext();

  const [triggerSaveTravelDates, setTriggerSaveTravelDates] = useState(0);
  const moreButtonContainerRef = useRef<HTMLDivElement>(null);

  const travelDatesDropdownRef =
    useFdrTravelDatesDropdownPosition(isControlOpened);

  const handleSaveTravelDates = () => {
    if (initialState && (isControlOpened || isOpened(EFdrDatesInputType.ANY))) {
      trackModalApply(initialState, TravelApplySourceType.BACKGROUND);
    }

    closeControl();

    if (customConfiguration?.flightActiveType) return;
    setTriggerSaveTravelDates(value => value + 1);
  };

  const closeOnSelection =
    customConfiguration?.closeOnSelection ||
    extendCustomConfig?.closeOnSelection;

  useFdrCloseDropdownLogic(
    [moreButtonContainerRef, textInputRef],
    handleSaveTravelDates
  );

  const handler = {
    ...travelDatesHandlers,
    setDepartureDate(day: Date) {
      travelDatesHandlers.setDepartureDate(day);

      if (closeOnSelection) {
        travelDatesHandlers.setReturnDate(addDays(day, DEFAULT_DURATION_DAYS));
        openCalendar(EFdrDatesInputType.RETURN_DATE)();
      }
    },
    setReturnDate(day: Date) {
      travelDatesHandlers.setReturnDate(day);
      if (closeOnSelection) {
        setTimeout(() => {
          trackCalendarApply({ ...state, returnDate: day });
          closeCalendar();
        });
      }
    },
  };

  const onSaveTravelDates = (input: IFdrTravelDatesState) => {
    if (closeOnSelection) {
      if (
        initialState &&
        input?.returnDate &&
        input?.departureDate &&
        !areTravelDatesEqual(input, initialState)
      ) {
        closeControl();
      }

      selectDate(input);
      setTravelDatesData(input);
    } else {
      setTravelDatesData(input);
      setTriggerSaveTravelDates(0);

      if (!triggerSaveTravelDates) {
        trackModalApply(input, TravelApplySourceType.BUTTON);
      }
    }
  };

  const initialMonth = useMemo(() => {
    if (closeOnSelection) {
      return isOpened(EFdrDatesInputType.DEPARTURE_DATE)
        ? state.departureDate
        : state.returnDate;
    }

    return initialState.departureDate;
  }, [initialState, customConfiguration, extendCustomConfig]);

  useFdrFlightTravelDatesHelper({
    travelDatesHandlers,
    customConfiguration,
    state,
  });

  const onChangeExt = e => {
    onChange && onChange(e);
  };

  return (
    <TravelDatesDesktopDropdownWrap>
      <DropdownCalendar
        key={
          isOpened(EFdrDatesInputType.DEPARTURE_DATE)
            ? EFdrDatesInputType.DEPARTURE_DATE
            : EFdrDatesInputType.RETURN_DATE
        }
        dropdownRef={travelDatesDropdownRef}
        containerRef={moreButtonContainerRef}
        side={side}
        travelDatesState={state}
        initialReturnDate={initialState.returnDate}
        travelDatesHandlers={handler}
        customConfiguration={{ ...customConfiguration, ...extendCustomConfig }}
        configuration={{
          ...configuration,
          initialMonth,
          ...extendConfig,
        }}
        isShown={isControlOpened}
        onSaveTravelDates={onSaveTravelDates}
        onChange={onChangeExt}
        triggerSaveTravelDates={triggerSaveTravelDates}
        closeDropdown={closeControl}
      >
        {children}
      </DropdownCalendar>
    </TravelDatesDesktopDropdownWrap>
  );
};

export default AllInDesktop;

import { useTranslation } from 'next-i18next';
import React, { PropsWithChildren, ReactElement } from 'react';
import { FdrTravelPeriodType } from '@hotelplan/supergraph-api';
import { useSearchTravelDatesContext } from 'fdr/components/candidate/fdr-search-travel-dates-context';
import { FdrDatesController } from 'fdr/components/local/fdr-search-travel-dates/dropdowns/fdr-dates-controller';
import { EFdrDatesInputType } from 'fdr/components/local/fdr-search-travel-dates/dropdowns/use-fdr-travel-dates-dropdown-handler';
import { FdrDateInput } from './fdr-date-input';

export function FdrCompactDates({ children }: PropsWithChildren): ReactElement {
  const [t] = useTranslation('common');

  const {
    state,
    initialState,
    datesHandler: { isOpened, openCalendar },
  } = useSearchTravelDatesContext();

  const returnDate = state.returnDate || initialState.returnDate;
  const departureDate = state.departureDate || initialState.departureDate;
  const isExactSearchType = state.searchType === FdrTravelPeriodType.Exact;
  const translationPostfix = isExactSearchType ? '.exact' : '';

  return (
    <FdrDatesController>
      <FdrDateInput
        label={t(
          `search_form_label.travel_dates.departure${translationPostfix}`
        )}
        date={departureDate}
        isOpened={isOpened(EFdrDatesInputType.DEPARTURE_DATE)}
        openCalendar={openCalendar(EFdrDatesInputType.DEPARTURE_DATE)}
      />
      <FdrDateInput
        label={t(`search_form_label.travel_dates.return${translationPostfix}`)}
        date={returnDate}
        isOpened={isOpened(EFdrDatesInputType.RETURN_DATE)}
        openCalendar={openCalendar(EFdrDatesInputType.RETURN_DATE)}
      />
      {children}
    </FdrDatesController>
  );
}

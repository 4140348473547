import React, { ReactElement } from 'react';
import styled from 'styled-components';
import {
  FdrTravelDatesConfiguration,
  FdrTravelDatesCustomConfiguration,
  FdrTravelDatesMobileModal,
  FdrWeekDaysList,
} from '@hotelplan/fdr.regular.fusion.fdr-travel-dates';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { useSearchTravelDatesContext } from 'fdr/components/candidate/fdr-search-travel-dates-context';
import {
  selectDate,
  useFdrFlightTravelDatesHelper,
} from 'fdr/components/local/fdr-search-travel-dates/dropdowns/use-fdr-flight-travel-dates-helper';
import { EFdrDatesInputType } from 'fdr/components/local/fdr-search-travel-dates/dropdowns/use-fdr-travel-dates-dropdown-handler';
import {
  trackCalendarApply,
  trackModalApply,
} from 'fdr/components/local/fdr-search-travel-dates/fdr-search-travel-dates.tracking';

const TravelDatesMobileDropdownWrap = styled(FdrTravelDatesMobileModal)(
  sx2CssThemeFn({ '.flexible': { mt: ['180px', 0] } })
);

const CompactTravelDatesMobileDropdownWrap = styled(FdrTravelDatesMobileModal)(
  sx2CssThemeFn(({ theme: { FONT_SIZE } }) => ({
    '.modal-header': FONT_SIZE.S,
    '.week-day-list-wrapper': {
      top: 'auto',
    },
  }))
);

const NUMBER_OF_MONTH_MOBILE = 13;
const configuration: FdrTravelDatesConfiguration = {
  hasApplyBtn: true,
  hasCustomWeekDayElement: true,
  hasNavbar: false,
  numberOfMonthsToShow: NUMBER_OF_MONTH_MOBILE,
  canChangeMonth: false,
  closeOnSaveButtonClick: true,
};

type TProps = {
  footerChildren?: ReactElement;
  closeModal?(): void;
  title?: string;
  className?: string;
  extendedCustomConfiguration?: FdrTravelDatesCustomConfiguration;
  extendConfig?: Partial<FdrTravelDatesConfiguration>;
  range?: boolean;
};

const AllInMobile: React.FC<TProps> = ({
  footerChildren,
  closeModal,
  title,
  extendedCustomConfiguration,
  extendConfig = {},
  className,
  range,
}: TProps) => {
  const {
    state,
    localState,
    travelDatesHandlers,
    onChange,
    customConfiguration,
    modalOrDropdownHandler: { isControlOpened, closeControl },
    setTravelDatesData,
    datesHandler: { isOpened, closeCalendar },
    resetLocalState,
  } = useSearchTravelDatesContext();

  const Wrapper = range
    ? CompactTravelDatesMobileDropdownWrap
    : TravelDatesMobileDropdownWrap;

  const isReturnOpened = isOpened(EFdrDatesInputType.RETURN_DATE);

  const keyDate = isReturnOpened
    ? localState.returnDate
    : localState.departureDate;

  const closeAction = closeModal || closeControl;
  const closeModalAction = () => {
    closeAction();
    resetLocalState();
  };

  const handler = {
    ...travelDatesHandlers,
    setReturnDate(day: Date) {
      travelDatesHandlers.setReturnDate(day);
      if (range) {
        setTimeout(() => {
          trackCalendarApply({ ...state, returnDate: day });
          closeCalendar();
        });
      }
    },
  };

  const updateTravelDates = dates => {
    selectDate(dates);
    trackModalApply(dates);
    setTravelDatesData(dates);
  };

  useFdrFlightTravelDatesHelper({
    travelDatesHandlers,
    customConfiguration,
    state,
  });

  return (
    <Wrapper
      key={keyDate.toDateString()}
      className={className}
      travelDatesState={state}
      onChange={onChange}
      travelDatesHandlers={handler}
      show={isControlOpened}
      closeModal={closeModalAction}
      closeOnSaveAction={closeAction}
      customConfiguration={{
        ...customConfiguration,
        hideFooterInformation: true,
        ...extendedCustomConfiguration,
      }}
      configuration={{ ...configuration, ...extendConfig }}
      setTravelDatesData={updateTravelDates}
      footerChildren={footerChildren}
      title={title}
    >
      <FdrWeekDaysList firstDayOfWeek={1} />
    </Wrapper>
  );
};

export default AllInMobile;

import React, { PropsWithChildren, ReactElement, useContext } from 'react';
import { useArrayNestedField } from '@hotelplan/components.common.forms';
import {
  FdrTravelDatesCustomConfiguration,
  FdrTravelDatesHandlers,
  IFdrDurationValue,
  IFdrTravelDatesState,
  useFdrTravelDatesState,
} from '@hotelplan/fdr.regular.fusion.fdr-travel-dates';
import { useIsomorphicLayoutEffect } from '@hotelplan/libs.hooks-react';
import { FdrFlightType } from '@hotelplan/supergraph-api';
import {
  TFdrModalOrDropdownHandler,
  useFdrModalOrDropdown,
} from 'fdr/components/local/fdr-search-travel-dates/dropdowns/use-fdr-modal-or-dropdown';
import {
  TFdrDatesDropdownHandler,
  useFdrTravelDatesDropdownHandler,
} from 'fdr/components/local/fdr-search-travel-dates/dropdowns/use-fdr-travel-dates-dropdown-handler';
import {
  SELECT_DAY_EVENT,
  SELECT_DURATION_EVENT,
} from 'fdr/components/local/fdr-search-travel-dates/fdr-search-travel-dates.constants';

interface IFdrSearchTravelDatesContext {
  initialState: IFdrTravelDatesState;
  state: IFdrTravelDatesState;
  resetLocalState(b?: boolean): void;
  resetState(): void;
  updateSate(s: IFdrTravelDatesState): void;
  localState: IFdrTravelDatesState;
  localHandlers: FdrTravelDatesHandlers;
  setDuration: FdrTravelDatesHandlers['setDuration'];
  setSearchType: FdrTravelDatesHandlers['setSearchType'];
  customConfiguration?: FdrTravelDatesCustomConfiguration;
  travelDatesHandlers: FdrTravelDatesHandlers;
  datesHandler: TFdrDatesDropdownHandler;
  setTravelDatesData: (nextValue: IFdrTravelDatesState) => void;
  onChange?: (travelDates: IFdrTravelDatesState | null) => void;
  side?: string;
  modalOrDropdownHandler: TFdrModalOrDropdownHandler;
  customFlexibleDurationValues?: IFdrDurationValue[];
}

export const FdrSearchTravelDatesContext =
  React.createContext<IFdrSearchTravelDatesContext>({
    initialState: undefined,
    state: undefined,
    localState: undefined,
    resetLocalState: undefined,
    resetState: undefined,
    updateSate: undefined,
    localHandlers: undefined,
    setDuration: undefined,
    setSearchType: undefined,
    customConfiguration: undefined,
    travelDatesHandlers: undefined,
    datesHandler: undefined,
    setTravelDatesData: undefined,
    onChange: undefined,
    side: undefined,
    modalOrDropdownHandler: undefined,
    customFlexibleDurationValues: undefined,
  });

interface IFdrSearchTravelDatesContextProviderProps {
  parentItemIndex?: number;
  parentItemName?: string;
  onChange?: (travelDates: IFdrTravelDatesState | null) => void;
  side?: string;
  customConfiguration?: FdrTravelDatesCustomConfiguration;
  customFlexibleDurationValues?: IFdrDurationValue[];
}

export function FdrSearchTravelDatesContextProvider({
  parentItemName,
  parentItemIndex,
  customConfiguration,
  customFlexibleDurationValues,
  onChange,
  side,
  children,
}: PropsWithChildren<IFdrSearchTravelDatesContextProviderProps>): ReactElement {
  const [initialState, setTravelDatesData] =
    useArrayNestedField<IFdrTravelDatesState | null>(
      'travelDates',
      parentItemName,
      parentItemIndex
    );
  const setTravelDatesDataCb = arg => {
    setTravelDatesData(arg);
  };

  const { state, setDuration, setSearchType, ...travelDatesHandlers } =
    useFdrTravelDatesState(initialState);

  const { state: localState, ...localHandlers } =
    useFdrTravelDatesState(initialState);

  const datesHandler = useFdrTravelDatesDropdownHandler();
  const modalOrDropdownHandler = useFdrModalOrDropdown();

  const resetLocalState = iseRet => {
    if (typeof iseRet === 'undefined') {
      localHandlers.resetDates(state);
    }

    iseRet && localHandlers.resetDates({ returnDate: state.returnDate });
    !iseRet && localHandlers.resetDates({ departureDate: state.departureDate });
  };

  const resetState = () => {
    travelDatesHandlers.resetDates(initialState);
    localHandlers.resetDates(initialState);
  };

  const updateSate = s => {
    travelDatesHandlers.resetDates(s);
    localHandlers.resetDates(s);
  };

  useIsomorphicLayoutEffect(() => {
    if (
      customConfiguration?.flightActiveType === FdrFlightType.Return ||
      !customConfiguration?.flightActiveType
    )
      return;

    const listenSelectDate = event => {
      travelDatesHandlers.setDepartureDate(event.detail.departureDate);
      travelDatesHandlers.setReturnDate(event.detail.returnDate);
    };

    window.addEventListener(SELECT_DAY_EVENT, listenSelectDate);

    return () => {
      window.removeEventListener(SELECT_DAY_EVENT, listenSelectDate);
    };
  }, []);

  useIsomorphicLayoutEffect(() => {
    const listenSelectDuration = event => {
      setDuration(event.detail);
    };

    window.addEventListener(SELECT_DURATION_EVENT, listenSelectDuration);

    return () => {
      window.removeEventListener(SELECT_DURATION_EVENT, listenSelectDuration);
    };
  }, []);

  return (
    <FdrSearchTravelDatesContext.Provider
      value={{
        state,
        setDuration,
        setSearchType,
        travelDatesHandlers,
        initialState,
        localState,
        localHandlers,
        datesHandler,
        setTravelDatesData: setTravelDatesDataCb,
        customConfiguration,
        onChange,
        side,
        modalOrDropdownHandler,
        resetLocalState,
        resetState,
        updateSate,
        customFlexibleDurationValues,
      }}
    >
      {children}
    </FdrSearchTravelDatesContext.Provider>
  );
}

export function useSearchTravelDatesContext() {
  return useContext(FdrSearchTravelDatesContext);
}

import React, { PropsWithChildren, ReactElement } from 'react';
import { FdrTravelDatesControllers } from '@hotelplan/fdr.regular.fusion.fdr-travel-dates';
import { useSearchTravelDatesContext } from 'fdr/components/candidate/fdr-search-travel-dates-context';

export function FdrDatesController({
  children,
}: PropsWithChildren): ReactElement {
  const {
    state,
    setDuration,
    setSearchType,
    initialState,
    customConfiguration,
    customFlexibleDurationValues,
  } = useSearchTravelDatesContext();

  return (
    <FdrTravelDatesControllers
      travelDatesState={state}
      setDuration={setDuration}
      setSearchType={setSearchType}
      hasCustomWeekDayElement={false}
      labelWithColon={false}
      customConfiguration={customConfiguration}
      initialReturnDate={initialState.returnDate}
      customFlexibleDurationValues={customFlexibleDurationValues}
    >
      {children}
    </FdrTravelDatesControllers>
  );
}

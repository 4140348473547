import { useTranslation } from 'next-i18next';
import React, { ReactElement, useRef } from 'react';
import { useSearchTravelDatesContext } from 'fdr/components/candidate/fdr-search-travel-dates-context';
import { FdrMixedCalendar } from 'fdr/components/local/fdr-mixed-calendar/fdr-mixed-calendar';
import { useFdrCloseDropdownLogic } from 'fdr/components/local/fdr-search-travel-dates/dropdowns/use-close-dropdown-logic';
import { FdrCompactDates } from './compact-dates';
import { FdrCompactFooter } from './fdr-compact-footer';
import { FdrWrapper } from './fdr-compact-select.styles';

export function FdrCompactDesktop(): ReactElement {
  const [t] = useTranslation(['common', 'forms']);

  const {
    modalOrDropdownHandler: { closeControl, isControlOpened },
    datesHandler: { closeCalendar },
    resetState,
  } = useSearchTravelDatesContext();

  const wrapperRef = useRef<HTMLDivElement>();

  useFdrCloseDropdownLogic([wrapperRef], () => {
    resetState();

    closeCalendar();
    setTimeout(closeControl);
  });

  if (!isControlOpened) return null;

  return (
    <FdrWrapper ref={wrapperRef} className="desktop">
      <FdrCompactDates>
        <FdrMixedCalendar desktop={true} wrapperRef={wrapperRef} />
      </FdrCompactDates>
      <FdrCompactFooter applyText={t('forms:apply.button')} />
    </FdrWrapper>
  );
}

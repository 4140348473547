import addDays from 'date-fns/addDays';
import addYears from 'date-fns/addYears';
import subYears from 'date-fns/subYears';
import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { FdrTravelDatesDesktopDropdown as DropdownCalendar } from '@hotelplan/fdr.regular.fusion.fdr-travel-dates';
import { trackCalendarApply } from 'components/domain/search-travel-dates/tracking';
import { useSearchTravelDatesContext } from 'fdr/components/candidate/fdr-search-travel-dates-context';
import {
  Header,
  Title,
} from 'fdr/components/local/fdr-mixed-calendar/fdr-mixed-calendar.styles';
import { useMixedCalendarConfig } from 'fdr/components/local/fdr-mixed-calendar/useMixedCalendarConfig';
import { useFdrCloseDropdownLogic } from 'fdr/components/local/fdr-search-travel-dates/dropdowns/use-close-dropdown-logic';
import { EFdrDatesInputType } from 'fdr/components/local/fdr-search-travel-dates/dropdowns/use-fdr-travel-dates-dropdown-handler';

const Calendar = styled(DropdownCalendar)(({ theme: { colors } }) => ({
  '&.dropdown-mobile-calendar': {
    '.travel-dates-information': {
      display: 'none',
    },
    '.footer': {
      padding: '16px',
      borderRadius: '5px',
      textAlign: 'center',
      '.applyButton': {
        width: '100%',
      },
    },
    '.body': {
      marginTop: '8px !important',
    },
    '.date-picker .DayPicker-Month .DayPicker-Caption': {
      marginBottom: '6px',
    },
    '.date-picker .DayPicker-Month .DayPicker-Weekdays': {
      borderTop: `1px solid ${colors.lightGreyFirst}`,
      borderBottom: `1px solid ${colors.lightGreyFirst}`,

      '&:after': {
        display: 'none',
      },
    },
  },
  '.date-picker .DayPicker-Month': {
    width: '100%',
  },
  '.date-picker .DayPicker-Weekdays  .DayPicker-Weekday': {
    fontSize: '16px',
  },
  '.date-picker .DayPicker-NavBar svg': {
    width: '24px',
    height: '24px',
  },
  '.date-picker .DayPicker-NavBar button': {
    padding: '0',
  },
}));

const today = new Date();

const configuration = {
  hasApplyBtn: false,
  hasNavbar: true,
  hasCustomWeekDayElement: false,
  numberOfMonthsToShow: 1,
  canChangeMonth: true,
  changeYears: {
    enabled: true,
    minYear: subYears(today, 1).getFullYear(), // 1 year before
    maxYear: addYears(today, 1).getFullYear(), // 1 year after
  },
  disableFooter: true,
};

const DEFAULT_DURATION_DAYS = 14;

type TProps = React.PropsWithChildren<{
  desktop: boolean;
  wrapperRef: React.MutableRefObject<HTMLDivElement>;
}>;

export function FdrMixedCalendar({ desktop, wrapperRef, children }: TProps) {
  const [t] = useTranslation('forms');
  const {
    datesHandler: { isOpened, closeCalendar },
    initialState,
    state,
    travelDatesHandlers,
    localState,
    onChange,
    updateSate,
  } = useSearchTravelDatesContext();

  const { title, customConfig, month: initialMonth } = useMixedCalendarConfig();

  const datePickerDropdownRef = useRef<HTMLDivElement>(null);
  const datePickerContainerRef = useRef<HTMLDivElement>(null);

  useFdrCloseDropdownLogic(
    [datePickerContainerRef, datePickerDropdownRef, wrapperRef],
    closeCalendar
  );
  if (!isOpened(EFdrDatesInputType.ANY)) return;

  const handler = {
    ...travelDatesHandlers,
    setDepartureDate(day: Date) {
      const newReturn = addDays(day, DEFAULT_DURATION_DAYS);

      travelDatesHandlers.setDepartureDate(day);
      travelDatesHandlers.setReturnDate(newReturn);
      setTimeout(() => {
        trackCalendarApply({
          ...state,
          departureDate: day,
          returnDate: newReturn,
        });

        closeCalendar();
      });
    },
    setReturnDate(day: Date) {
      travelDatesHandlers.setReturnDate(day);
      setTimeout(() => {
        trackCalendarApply({ ...state, returnDate: day });
        closeCalendar();
      });
    },
  };

  const month = desktop
    ? initialMonth
    : customConfig.onlyReturnDate
    ? localState.returnDate
    : localState.departureDate;

  return (
    <Calendar
      key={month.toDateString()}
      applyText={t('forms:apply.button')}
      travelDatesState={state}
      className={desktop ? 'dropdown-calendar' : 'dropdown-mobile-calendar'}
      containerRef={datePickerContainerRef}
      dropdownRef={datePickerDropdownRef}
      isShown={true}
      side="right"
      initialReturnDate={initialState.returnDate}
      travelDatesHandlers={handler}
      customConfiguration={{ ...customConfig, closeOnSelection: true }}
      onChange={onChange}
      configuration={{
        ...configuration,
        initialMonth: month,
        disableFooter: desktop,
        hasApplyBtn: false,
        closeOnSaveButtonClick: true,
      }}
      closeDropdown={closeCalendar}
      onSaveTravelDates={dates => {
        updateSate(dates);
      }}
      icons={{
        minusYear: `double-arrow-button-left`,
        plusYear: `double-arrow-button-right`,
        minusMonth: `arrow-button-left`,
        plusMonth: `arrow-button-right`,
      }}
    >
      <Header>
        <Title className="title">{title}</Title>
        {children}
      </Header>
    </Calendar>
  );
}

import { useEffect } from 'react';
import {
  FdrTravelDatesCustomConfiguration,
  FdrTravelDatesHandlers,
  IFdrTravelDatesState,
} from '@hotelplan/fdr.regular.fusion.fdr-travel-dates';
import { FdrFlightType } from '@hotelplan/supergraph-api';
import { SELECT_DAY_EVENT } from 'fdr/components/local/fdr-search-travel-dates/fdr-search-travel-dates.constants';

export const selectDate = input => {
  if (typeof window === 'undefined') return;
  window.dispatchEvent(new CustomEvent(SELECT_DAY_EVENT, { detail: input }));
};

interface IFlightTravelDatesHelper {
  customConfiguration: FdrTravelDatesCustomConfiguration;
  state: IFdrTravelDatesState;
  travelDatesHandlers: FdrTravelDatesHandlers;
}

export function useFdrFlightTravelDatesHelper({
  customConfiguration,
  state,
  travelDatesHandlers,
}: IFlightTravelDatesHelper) {
  useEffect(() => {
    if (
      customConfiguration?.flightActiveType === FdrFlightType.Return ||
      !customConfiguration?.flightActiveType
    )
      return;

    travelDatesHandlers.setReturnDate(state.departureDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customConfiguration?.flightActiveType]);
}

import React, { ReactElement } from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { FdrFlightSearchDropdown } from 'fdr/components/local/fdr-search-dropdown/fdr-flight-search-dropdown/fdr-flight-search-dropdown';
import { FdrCompactDesktop } from './compact-select/fdr-compact-desktop';
import { FdrCompactMobile } from './compact-select/fdr-compact-mobile';

interface IFdrDropdownVariantsProps {
  textInputRef: React.RefObject<HTMLInputElement>;
  noVariants?: boolean;
}

export function FdrDropdownVariants({
  textInputRef,
  noVariants,
}: IFdrDropdownVariantsProps): ReactElement {
  const { mobile } = useDeviceType();

  if (noVariants)
    return <FdrFlightSearchDropdown textInputRef={textInputRef} />;

  if (mobile) return <FdrCompactMobile />;

  return <FdrCompactDesktop />;
}

import styled from 'styled-components';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const Title = styled.div(({ theme: { colors, FONT_SIZE } }) =>
  sx2CssThemeFn({
    ...FONT_SIZE.S,
    paddingBottom: '8px',
    margin: '8px 18px -10px',
    borderBottom: `1px solid ${colors.lightGreyFirst}`,
  })
);

export const ModalCloseButton = styled(BsButtonWithIcon)(
  sx2CssThemeFn({
    ml: 'auto',
    backgroundColor: 'inherit',
    '.icon': {
      variant: ['icons.md', 'icons.lg'],
    },
  })
);

export const Header = styled.div(({ theme: { colors } }) => ({
  display: 'flex',
  borderBottom: `1px solid ${colors.lightGreyFirst}`,
  padding: '8px 16px 8px',
  '.title': {
    borderBottom: '0',
    paddingBottom: '0',
    margin: 0,
  },
}));

import React from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import AllInMobileFooter from 'fdr/components/local/fdr-search-dropdown/fdr-flight-search-dropdown/all-in/all-in-mobile-footer';
import { FdrDatesController } from 'fdr/components/local/fdr-search-travel-dates/dropdowns/fdr-dates-controller';
import AllInDesktop from './all-in/all-in-desktop';
import AllInMobile from './all-in/all-in-mobile';

type TProps = {
  textInputRef: React.RefObject<HTMLInputElement>;
};

export function FdrFlightSearchDropdown({ textInputRef }: TProps) {
  const { mobile } = useDeviceType();

  return (
    <>
      {mobile && <AllInMobile footerChildren={<AllInMobileFooter />} />}
      {!mobile && (
        <AllInDesktop textInputRef={textInputRef}>
          <FdrDatesController />
        </AllInDesktop>
      )}
    </>
  );
}

import React from 'react';
import {
  SearchFormWrap,
  TravelTypes,
} from './fdr-search-control-form-layout.styles';
import { IFdrSearchControlFormLayoutProps } from './fdr-search-control-form-layout.types';

export const FdrSearchControlFormLayout: React.FC<IFdrSearchControlFormLayoutProps> =
  ({ travelTypes, children, className }) => {
    return (
      <SearchFormWrap
        className={`searchControlFormLayoutWrapper ${className}`}
        id="search-form-wrap"
      >
        {travelTypes && (
          <TravelTypes className="travelTypes">{travelTypes}</TravelTypes>
        )}
        {children}
      </SearchFormWrap>
    );
  };

import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const searchFormWrapCss = sx2CssThemeFn({
  width: '100%',
  maxWidth: '1170px',
  mx: [null, 'auto'],
  borderRadius: [null, 'default'],
  position: 'relative',
  backgroundColor: 'background',
  zIndex: [null, '3'],
  overflowX: ['auto', `visible`],
});

export const SearchFormWrap = styled.div(searchFormWrapCss);

export const TravelTypes = styled.div(({ theme: { media } }) => ({
  [media.tablet]: {
    overflow: 'hidden',
  },
}));

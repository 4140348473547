import React from 'react';
import {
  trackCalendarClose,
  trackCalendarOpen,
} from 'fdr/components/local/fdr-search-travel-dates/fdr-search-travel-dates.tracking';
import { useFdrDropdownLogic } from './use-fdr-dropdown-logic';

export enum EFdrDatesInputType {
  RETURN_DATE = 'RETURN_DATE',
  DEPARTURE_DATE = 'DEPARTURE_DATE',
  ANY = 'ANY',
}

export type TFdrDatesDropdownHandler = {
  getRef(type: EFdrDatesInputType): React.MutableRefObject<HTMLInputElement>;
  isOpened(type: EFdrDatesInputType): boolean;
  openCalendar(type: EFdrDatesInputType): () => void;
  closeCalendar: () => void;
};

export function useFdrTravelDatesDropdownHandler(): TFdrDatesDropdownHandler {
  const [refDep, isOpenedDep, openDep, closeDep] = useFdrDropdownLogic();
  const [refRet, isOpenedRet, openRet, closeRet] = useFdrDropdownLogic();

  const getRef = (type: EFdrDatesInputType) => {
    if (type === EFdrDatesInputType.DEPARTURE_DATE) {
      return refDep;
    }

    if (type === EFdrDatesInputType.RETURN_DATE) {
      return refRet;
    }

    return null;
  };

  const isOpened = (type: EFdrDatesInputType) => {
    if (type === EFdrDatesInputType.DEPARTURE_DATE) {
      return isOpenedDep;
    }

    if (type === EFdrDatesInputType.RETURN_DATE) {
      return isOpenedRet;
    }

    if (type === EFdrDatesInputType.ANY) {
      return isOpenedRet || isOpenedDep;
    }

    return false;
  };

  const openCalendar = (type: EFdrDatesInputType) => () => {
    if (!isOpenedRet && !isOpenedDep) trackCalendarOpen();

    if (type === EFdrDatesInputType.DEPARTURE_DATE) {
      closeRet();
      openDep();
    }

    if (type === EFdrDatesInputType.RETURN_DATE) {
      closeDep();
      openRet();
    }
  };

  const closeCalendar = () => {
    if (isOpenedRet || isOpenedDep) setTimeout(trackCalendarClose);

    closeRet();
    closeDep();
  };

  return { getRef, isOpened, openCalendar, closeCalendar };
}

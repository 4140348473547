import React, { ReactElement } from 'react';
import {
  SHORT_INFO_FORMAT,
  FdrTravelDatesInput,
} from '@hotelplan/fdr.regular.fusion.fdr-travel-dates';
import { formatDate } from '@hotelplan/libs.utils';
import CustomLabel from 'components/domain/CustomLabel';
import { ENTER_KEY } from 'components/domain/keyboard-navigation/keyboard-navigation.constant';

const formatDateArrayToString = (date: Array<Date | null>) => {
  const formattedDate = date
    .map(value => (value ? formatDate(value, SHORT_INFO_FORMAT) : ''))
    .filter(Boolean);

  return formattedDate.join(' – ');
};

interface IFdrDateInputProps {
  date: Date | Array<Date | null>;
  label?: React.ReactNode;
  openCalendar: () => void;
  isOpened: boolean;
}

export function FdrDateInput({
  date,
  label,
  isOpened,
  openCalendar,
}: IFdrDateInputProps): ReactElement {
  const travelDatesValue = Array.isArray(date)
    ? formatDateArrayToString(date)
    : formatDate(date, SHORT_INFO_FORMAT);

  return (
    <div className="header-group">
      <FdrTravelDatesInput
        name="travelDates"
        icon={{ name: 'calendar' }}
        className={`data-input ${isOpened ? 'active' : ''}`}
        customLabel={<CustomLabel htmlFor="travelDates">{label}</CustomLabel>}
        readOnly
        value={travelDatesValue}
        onClick={openCalendar}
        onKeyPress={e => {
          if (e.key === ENTER_KEY) {
            e.preventDefault();
            open();
          }
        }}
      />
    </div>
  );
}

import {
  expandRange,
  EXACT_DURATION_VALUES,
  IFdrDurationValue,
} from '@hotelplan/fdr.regular.fusion.fdr-travel-dates';

export const FDR_CUSTOM_FLEXIBLE_DURATION_VALUES: IFdrDurationValue[] = [
  ...EXACT_DURATION_VALUES,
  { duration: expandRange([1, 8]), type: 'night' },
  { duration: expandRange([1, 3]), type: 'night' },
  { duration: expandRange([3, 7]), type: 'night' },
  { duration: expandRange([4, 5]), type: 'night' },
  { duration: expandRange([6, 8]), type: 'night' },
  { duration: expandRange([7, 10]), type: 'night' },
  { duration: expandRange([9, 15]), type: 'night' },
  { duration: expandRange([16, 22]), type: 'night' },
];

export const SELECT_DAY_EVENT = `select_day`;
export const SELECT_DURATION_EVENT = `select_duration`;

import styled from 'styled-components';
import { FdrMobileModal } from '@hotelplan/fdr.regular.fusion.fdr-travel-dates';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const FdrMobileModalWrapper = styled(FdrMobileModal)({
  '.modal-wrapper': {
    maxHeight: '100%',
    borderRadius: 0,
  },
  '.shadow-overlay': {
    backgroundColor: 'white',
  },

  '.compact-dropdown-footer': {
    position: 'sticky',
    top: '100%',
  },
  '.modal-header': {
    padding: '13px 16px',
  },
});

export const FdrWrapper = styled.div(
  sx2CssThemeFn(({ theme: { colors } }) => ({
    background: 'white',
    width: '100%',
    zIndex: 9,
    '&.desktop': {
      position: 'absolute',
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1)',
    },
    '&.mobile': {
      marginTop: '20px',
    },
    '.dropdown-mobile-calendar': {
      position: 'absolute',
      zIndex: 99999,
      top: '0',
      left: '0',
      backgroundColor: 'rgba(0,0,0,0.4)',
      height: '100%',
      width: '100%',
      '.absolute-dropdown': {
        margin: 'auto 16px',
        borderRadius: '5px',
        top: '50%',
        transform: 'translateY(-50%)',

        '.DayPicker-Weekdays': {
          position: 'relative',
          top: '6px',
        },
      },
    },
    '.dropdown-calendar': {
      position: 'absolute',
      zIndex: 99999,
      width: '385px',
      minHeight: '377px',
      left: '283px',
      top: '35px',
    },
    '.header': {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 0,
      borderBottom: 0,
    },
    '.header-group': {
      px: '16px',
      marginBottom: '16px',
    },
    '.switch-button': {
      width: '100%',
    },
    '.input': {
      height: '45px',
    },
    '.label-exact, .label-flexible': { fontSize: '14px', paddingLeft: '5px' },

    '.data-input': {
      '&.active input': {
        borderColor: colors.secondary,
      },
      svg: {
        width: '16px',
        height: '16px',
        left: '13px',
      },
      '.input': {
        paddingLeft: '35px',
      },
    },
  }))
);

export const FdrCompactFooterWrapper = styled.div(({ theme: { colors } }) => ({
  margin: '0 16px 16px',
  paddingTop: '4px',
  borderTop: `1px solid ${colors.lightGreyFirst}`,
  '.travel-dates-information': {
    textAlign: 'left',
    color: 'black',
    paddingLeft: 0,
  },
  '.applyButton': {
    borderRadius: '4px',
  },
}));

import { useField } from '@hotelplan/components.common.forms';
import { useTravelRooms } from '@hotelplan/components.common.travel-rooms';
import {
  EFdrFlightTravelType,
  U_Extended_TravelType,
} from 'fdr/components/candidate/fdr-search/types/travel-type.types';

export const useFdrFourTravelRooms = () => {
  const [travelType] =
    useField<U_Extended_TravelType | null>(`extended_travelType`);

  const isSearchForFourRoomsEnabled =
    travelType.fdr !== EFdrFlightTravelType.Flight;

  return useTravelRooms(isSearchForFourRoomsEnabled);
};

import { useTranslation } from 'next-i18next';
import React, { ReactElement, useRef } from 'react';
import {
  FdrTravelDatesCustomConfiguration,
  FdrTravelDatesField,
  IFdrTravelDatesState,
} from '@hotelplan/fdr.regular.fusion.fdr-travel-dates';
import { ENTER_KEY } from 'components/domain/keyboard-navigation/keyboard-navigation.constant';
import {
  FdrSearchTravelDatesContextProvider,
  useSearchTravelDatesContext,
} from 'fdr/components/candidate/fdr-search-travel-dates-context';
import NoSsr from 'fdr/components/local/no-ssr/no-ssr';
import { FdrDropdownVariants } from './dropdowns/fdr-dropdown-variants';
import { FDR_CUSTOM_FLEXIBLE_DURATION_VALUES } from './fdr-search-travel-dates.constants';
import { getTranslatedShortInfo } from './fdr-search-travel-dates.utils';

interface IFdrSearchTravelDatesProps {
  side?: string;
  disabled?: boolean;
  noVariants?: boolean;
  parentItemIndex?: number;
  parentItemName?: string;
  customLabel?: React.ReactNode;
  customConfiguration?: FdrTravelDatesCustomConfiguration;
  onChange?: (travelDates: IFdrTravelDatesState | null) => void;
}

export function FdrSearchTravelDates({
  side,
  disabled,
  noVariants,
  parentItemIndex,
  parentItemName,
  customLabel,
  customConfiguration,
  onChange,
}: IFdrSearchTravelDatesProps): ReactElement {
  return (
    <FdrSearchTravelDatesContextProvider
      parentItemIndex={parentItemIndex}
      parentItemName={parentItemName}
      customConfiguration={customConfiguration}
      onChange={onChange}
      side={side}
      customFlexibleDurationValues={FDR_CUSTOM_FLEXIBLE_DURATION_VALUES}
    >
      <FdrSearchTravelDatesChild
        disabled={disabled}
        customLabel={customLabel}
        noVariants={noVariants}
      />
    </FdrSearchTravelDatesContextProvider>
  );
}

interface IFdrSearchTravelDatesChildProps {
  disabled?: boolean;
  noVariants?: boolean;
  customLabel?: React.ReactNode;
}

function FdrSearchTravelDatesChild({
  disabled,
  customLabel,
  noVariants,
}: IFdrSearchTravelDatesChildProps): React.ReactElement {
  const { t } = useTranslation('search');

  const {
    initialState,
    customConfiguration,
    modalOrDropdownHandler: { isControlOpened, openControl },
  } = useSearchTravelDatesContext();

  const textInputRef = useRef<HTMLInputElement>(null);

  const translatedShortInformation = getTranslatedShortInfo(
    t,
    initialState,
    customConfiguration
  );

  return (
    <NoSsr>
      <FdrTravelDatesField
        disabled={disabled}
        textInputRef={textInputRef}
        isDropdownOpened={isControlOpened}
        translatedShortInformation={translatedShortInformation}
        customLabel={customLabel}
        onClick={openControl}
        onKeyPress={e => {
          if (e.key === ENTER_KEY) {
            e.preventDefault();
            openControl();
          }
        }}
      >
        <FdrDropdownVariants
          textInputRef={textInputRef}
          noVariants={noVariants}
        />
      </FdrTravelDatesField>
    </NoSsr>
  );
}

import React, { useRef } from 'react';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { useFdrCloseDropdownLogic } from './use-close-dropdown-logic';

export function useFdrDropdownLogic() {
  const ref = useRef<HTMLInputElement>(null);
  const [isOpened, open, close] = useToggleState(false);
  useFdrCloseDropdownLogic([ref], close);

  return [ref, isOpened, open, close] as [
    React.MutableRefObject<HTMLInputElement>,
    boolean,
    () => void,
    () => void
  ];
}

import {
  trackTravelDates,
  TravelApplySourceType,
  TravelDatesEventType,
} from '@hotelplan/libs.tracking';
import { track } from '@hotelplan/libs.tracking-events';
import { FdrTravelPeriodType } from '@hotelplan/supergraph-api';

export const trackCalendar = type => () =>
  track({ event: type, eventMetadata: {}, _clear: true });

export const trackApplyEvents =
  type => (state, source?: TravelApplySourceType) =>
    trackTravelDates({
      type,
      payload: {
        duration: state.duration.duration.join('-'),
        exact: state.searchType === FdrTravelPeriodType.Exact,
        from: state.departureDate,
        to: state.returnDate,
        source,
      },
    });

// *** trackCalendar ***

export const trackModalOpen = trackCalendar(
  TravelDatesEventType.TRAVEL_DATES_OPEN
);

export const trackModalClose = trackCalendar(
  TravelDatesEventType.TRAVEL_DATES_CLOSE
);

// *** trackApplyEvents ***

export const trackCalendarApply = trackApplyEvents(
  TravelDatesEventType.TRAVEL_DATES_APPLY_CALENDAR
);

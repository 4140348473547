import { useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import {
  FdrTravelDatesInformation,
  formatDuration,
  FdrApplyButton,
} from '@hotelplan/fdr.regular.fusion.fdr-travel-dates';
import { TravelApplySourceType } from '@hotelplan/libs.tracking';
import { useSearchTravelDatesContext } from 'fdr/components/candidate/fdr-search-travel-dates-context';
import { trackModalApply } from 'fdr/components/local/fdr-search-travel-dates/fdr-search-travel-dates.tracking';
import { FdrCompactFooterWrapper } from './fdr-compact-select.styles';

interface IFdrCompactFooterProps {
  applyText: string;
}

export function FdrCompactFooter({
  applyText,
}: IFdrCompactFooterProps): ReactElement {
  const [t, { language }] = useTranslation(['common', 'forms']);

  const {
    state,
    initialState,
    customConfiguration,
    setTravelDatesData,
    modalOrDropdownHandler: { closeControl },
    datesHandler: { closeCalendar },
  } = useSearchTravelDatesContext();

  const returnDate = state.returnDate || initialState.returnDate;

  return (
    <FdrCompactFooterWrapper className="compact-dropdown-footer">
      <FdrTravelDatesInformation
        returnDate={returnDate}
        duration={formatDuration(t, state.duration)}
        departureDate={state.departureDate}
        onlyDates={customConfiguration?.onlyDatesInfo}
        onlyDepartureDate={customConfiguration?.onlyDepartureDate}
        onlyReturnDate={customConfiguration?.onlyReturnDate}
        language={language}
      />
      <FdrApplyButton
        state={state}
        initialReturnDate={initialState.returnDate}
        saveTravelDates={dates => {
          trackModalApply(dates, TravelApplySourceType.BUTTON);
          setTravelDatesData(dates);
        }}
        postSaveAction={() => {
          closeControl();
          closeCalendar();
        }}
      >
        {applyText}
      </FdrApplyButton>
    </FdrCompactFooterWrapper>
  );
}
